<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content ion-text-center">
          <div>
            <ion-img
              :src="
                activePetValue.species?.id === 1
                  ? require(`@/assets/images/animated/dog-head.gif`)
                  : require(`@/assets/images/animated/cat-head.gif`)
              "
              alt="About kép"
              class="dog-icon"
            />
            <ion-text><small>v1.9.23</small></ion-text>
          </div>
          <div class="beta-tester" v-if="isAuthenticated">
            <div v-if="signUpAvailable">
              <ion-button shape="round" fill="solid" @click="betaTesterSignUp" class="btn">
                Feliratkozom a bétaprogramra
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>
              <p class="ion-margin-top">
                Jelenleg legalább egy hónap a várakozási idő a<br />bétaprogramunkba kerüléshez.
              </p>
            </div>
            <ion-text v-else>Feliratkoztál a bétaprogramra</ion-text>
          </div>
          <div class="social">
            <ion-text class="brand">DOX LABS Zrt.</ion-text>
            <ion-img
              :src="require('@/assets/images/icons/about/doxincircle.svg')"
              alt="DOXOCIAL webshop"
              class="social__icon"
              @click="openBrowserLink('https://dox-tag.eu')"
            />
            <ion-img
              :src="require('@/assets/images/icons/about/fb.svg')"
              alt="DOXOCIAL on Facebook"
              class="social__icon"
              @click="openLink('https://www.facebook.com/doxocial')"
            />
            <ion-img
              :src="require('@/assets/images/icons/about/insta.svg')"
              alt="DOXOCIAL on Instagram"
              class="social__icon"
              @click="openLink('https://www.instagram.com/doxocial')"
            />
            <ion-img
              :src="require('@/assets/images/icons/about/tiktok.svg')"
              alt="DOXOCIAL on TikTok"
              class="social__icon"
              @click="openLink('https://www.tiktok.com/@doxocial')"
            />
            <ion-img
              :src="require('@/assets/images/icons/about/youtube.svg')"
              alt="DOXOCIAL on Youtube"
              class="social__icon"
              @click="openLink('https://www.youtube.com/channel/UCCbc6LCm8rkYfXcgIbgMVFw')"
            />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { IonPage, IonContent, IonImg, IonText, IonButton, IonRippleEffect } from '@ionic/vue'

import usePets from '@/composables/pets'
import useAuth from '@/composables/auth'
import useNavigation from '@/composables/navigation'

export default defineComponent({
  name: 'AboutPage',
  components: {
    IonPage,
    IonContent,
    IonImg,
    IonText,
    IonButton,
    IonRippleEffect,
  },
  setup() {
    const { activePet } = usePets()
    const { isAuthenticated, AUTH_STATE, betaTesterSignUp } = useAuth()
    const { openBrowserLink, openLink } = useNavigation()

    const signUpAvailable = computed(() => !AUTH_STATE.user.beta_tester)

    const activePetValue = computed(() => activePet.value)

    return {
      activePetValue,
      openBrowserLink,
      openLink,
      isAuthenticated,
      signUpAvailable,
      betaTesterSignUp,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.container,
.content {
  height: calc(100vh - 120px);

  @media (min-width: 768px) {
    height: calc(100vh - 140px);
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px !important;
}

.dog-icon {
  max-width: 90px;
  margin: 0 auto;
}

.links {
  ion-item {
    --min-height: 2em;
  }
}

.beta-tester {
  font-size: 12px;
  font-weight: 600;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .btn {
    font-size: 14px;
    font-weight: 900;
    --box-shadow: none;
    letter-spacing: 0;
  }

  p {
    font-size: 10px;
    font-weight: 600;
  }
}

.link {
  font-weight: 700;
}

.logout {
  color: #f64141;
}

.social {
  .brand {
    display: block;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 10px;
  }

  &__icon {
    min-height: 25px;
    max-height: 25px;
    display: inline-flex;
    padding: 7.5px;
    cursor: pointer;
    transition: 0.1s ease-out;

    &:hover {
      transform: scale(1.15);
    }
  }
}
</style>
