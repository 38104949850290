<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <lottie-animation
            ref="anim"
            :autoPlay="false"
            :playOnMount="true"
            :playInterval="0"
            :animationData="require('@/assets/images/lottie/settings.json')"
            class="icon"
          />

          <div class="settings-block">
            <h1>Push értesítések</h1>
            <div class="hr"></div>
            <div class="settings-item">
              <div class="description">
                <h2>Tappancs</h2>
                Tappancsokról érkező értesítések küldése
              </div>
              <ion-toggle class="toggle" v-model="pushNotifications.like" @ionChange.prevent="handlePushChange" />
            </div>

            <div class="settings-item">
              <div class="description">
                <h2>Követések</h2>
                Követésekről érkező értesítések küldése
              </div>
              <ion-toggle class="toggle" v-model="pushNotifications.follow" @ionChange.prevent="handlePushChange" />
            </div>

            <div class="settings-item">
              <div class="description">
                <h2>Hozzászólások</h2>
                Hozzászólásokról érkező értesítések küldése
              </div>
              <ion-toggle class="toggle" v-model="pushNotifications.comment" @ionChange.prevent="handlePushChange" />
            </div>

            <div class="settings-item">
              <div class="description">
                <h2>Üzenetek</h2>
                Üzenetekről érkező értesítések küldése
              </div>
              <ion-toggle class="toggle" v-model="pushNotifications.message" @ionChange.prevent="handlePushChange" />
            </div>
          </div>

          <div class="settings-block">
            <h1>Emailek</h1>
            <div class="hr"></div>
            <div class="settings-item">
              <div class="description">
                <h2>Tappancs</h2>
                Tappancsokról érkező értesítések küldése emailben
              </div>
              <ion-toggle class="toggle" disabled />
            </div>
            <div class="settings-item">
              <div class="description">
                <h2>Követések</h2>
                Követésekről érkező értesítések küldése emailben
              </div>
              <ion-toggle class="toggle" disabled checked />
            </div>
            <div class="settings-item">
              <div class="description">
                <h2>Hozzászólások</h2>
                Hozzászólások küldése emailben
              </div>
              <ion-toggle class="toggle" disabled checked />
            </div>
            <div class="settings-item">
              <div class="description">
                <h2>Üzenetek</h2>
                Üzenetekről érkező értesítések küldése emailben
              </div>
              <ion-toggle class="toggle" disabled checked />
            </div>
          </div>

          <div class="settings-block">
            <h1>Adatvédelem</h1>
            <div class="hr"></div>
            <div class="settings-item" @click="openBrowserLink('https://dox-tag.eu/privacy-policy/')">
              <div class="description">
                <h2>Adatvédelmi elveink</h2>
                Az Adatvédelmi elvek megnyitása
              </div>
            </div>
            <div class="settings-item" @click="openBrowserLink('https://dox-tag.eu/aszf/')">
              <div class="description">
                <h2>Felhasználási feltételek</h2>
                A Felhasználási feltételek megnyitása
              </div>
            </div>
          </div>

          <h1 class="logout" @click="doLogout">Kijelentkezés a fiókból</h1>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { IonPage, IonContent, IonToggle, menuController } from '@ionic/vue'

import usePets from '@/composables/pets'
import useAuth from '@/composables/auth'
import useNavigation from '@/composables/navigation'
import useToast from '@/composables/toast'

export default defineComponent({
  name: 'SettingsPage',
  components: {
    IonPage,
    IonContent,
    IonToggle,
  },
  setup() {
    const { activePet } = usePets()
    const { isAuthenticated, AUTH_STATE, betaTesterSignUp, savePushSettings, logout } = useAuth()
    const { openBrowserLink, openLink } = useNavigation()
    const { openToast } = useToast()

    const signUpAvailable = computed(() => !AUTH_STATE.user.beta_tester)
    const pushNotifications = computed(() => ({
      like: AUTH_STATE.user.push?.like ?? false,
      follow: AUTH_STATE.user.push?.follow ?? false,
      comment: AUTH_STATE.user.push?.comment ?? false,
      message: AUTH_STATE.user.push?.message ?? false,
    }))

    const doLogout = async () => {
      try {
        await menuController.close()
        await logout()

        await openToast({
          message: 'WOOF, a DOXOCIAL visszavár!!',
          color: 'success',
        })
      } catch (error) {
        await openToast({
          message: 'Hiba történt a kijelentkezés során',
          color: 'danger',
        })
      }
    }

    const activePetValue = computed(() => activePet.value)

    const handlePushChange = async () => {
      await savePushSettings(pushNotifications.value)
    }

    return {
      activePetValue,
      openBrowserLink,
      openLink,
      isAuthenticated,
      signUpAvailable,
      pushNotifications,
      betaTesterSignUp,
      doLogout,
      handlePushChange,
    }
  },
})
</script>

<style lang="scss" scoped>
.icon {
  max-width: 70px;
  margin: 157px auto 57px;
}

.settings-block {
  margin-bottom: 50px;
  font-size: 10px;
  font-weight: 600;

  p {
    font-size: 10px;
  }

  .settings-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .description {
      flex-grow: 1;

      h2 {
        font-weight: 900;
        font-size: 14px;
        line-height: 0;
      }
    }

    .toggle {
      --background: #efefef;
      --background-checked: #ffd3d3;
      --handle-background: #e1e1e1;
      --handle-background-checked: #f64242;

      width: 36px;
      height: 18px;
      --handle-height: 18px;
      --handle-width: 18px;
      --handle-box-shadow: 0;
    }
  }

  h1 {
    font-size: 12px !important;
    margin-bottom: 5px;
  }
}

.logout {
  font-size: 12px !important;
  color: #f64242;
}

.hr {
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #fff, #dfdfdf 10%, #dfdfdf 90%, #fff);
}
</style>
