<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <PetSelector disabled newDesign />

          <template v-if="!invited">
            <h1 class="top">Gazdi meghívása</h1>

            <p>
              Hívd meg családtagod, hogy együtt kezelhessétek Kedvenceitek profiljait. A meghívott személy teljesértékű
              hozzáférést kap a fiókhoz.
            </p>

            <form @submit.prevent="submit" class="form" novalidate>
              <div class="form-group">
                <ion-label for="name" class="control-label">Családtag neve:</ion-label>
                <ion-input
                  id="name"
                  v-model="validations.name.$model"
                  name="name"
                  required="true"
                  enterkeyhint="go"
                  :class="{ 'is-invalid': validations.name.$error || hasServerValidationError('name') }"
                  class="form-control"
                />
                <ErrorMessage :field="validations.name" />
                <ServerValidation field="name" :server-validation="serverValidation" />
              </div>

              <div class="form-group">
                <ion-label for="email" class="control-label">Családtag email címe:</ion-label>
                <ion-input
                  id="email"
                  type="email"
                  v-model="validations.email.$model"
                  name="email"
                  required="true"
                  enterkeyhint="go"
                  :class="{ 'is-invalid': validations.email.$error || hasServerValidationError('email') }"
                  class="form-control"
                />
                <ErrorMessage :field="validations.email" />
                <ServerValidation field="email" :server-validation="serverValidation" />
              </div>

              <ion-button @click.prevent="submit" type="button" expand="full" shape="round" class="btn btn-link">
                Gazdi meghívása
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>
            </form>
          </template>

          <template v-else>
            <h1 class="top">Meghívó elküldve!</h1>

            <p>{{ name }} értesítést kap emailben a meghívásodról!</p>

            <lottie-animation
              ref="anim"
              :loop="true"
              :autoPlay="true"
              :animationData="require('@/assets/images/lottie/emailsent.json')"
            />

            <ion-button @click.prevent="goToFeed" type="button" expand="full" shape="round" class="btn btn-link">
              Rendben
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </template>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { IonPage, IonContent, IonLabel, IonInput, IonButton, IonRippleEffect } from '@ionic/vue'
import { required, email as emailValidation } from '@vuelidate/validators'

import useVuelidate from '@vuelidate/core'
import useAuth from '@/composables/auth'
import usePosts from '@/composables/posts'
import useFormControl from '@/composables/formControl'

import PetSelector from '@/components/Pet/PetSelector'
import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'Invite',
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonInput,
    IonButton,
    IonRippleEffect,
    PetSelector,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const { goToFeed } = usePosts()
    const { AUTH_STATE, inviteUser } = useAuth()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const name = ref('')
    const email = ref('')
    const isLoading = ref(false)
    const invited = ref(false)

    const isValidEmail = val => val !== AUTH_STATE.user.email

    const validations = useVuelidate(
      {
        name: {
          required,
        },
        email: {
          required,
          emailValidation,
          isValidEmail,
        },
      },
      {
        name,
        email,
      },
    )

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        await inviteUser(name.value, email.value)

        invited.value = true
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }
      } finally {
        isLoading.value = false
      }
    }

    return {
      submit,
      goToFeed,
      name,
      email,
      invited,
      validations,
      serverValidation,
      hasServerValidationError,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&family=Open+Sans:wght@400;800&display=swap');

.pet-select {
  height: unset;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 16px !important;
  text-align: center;
  letter-spacing: -0.3px;
  line-height: 19.5px;
}

p {
  margin: 0 30px;
  text-align: center;
  color: #222222;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 30px;
}

ion-input {
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 31px;
  border: none;
  min-height: 55px;
  --padding-start: 22px;
  --padding-end: 22px;
}

ion-label {
  padding-left: 22px;
}

ion-button {
  height: 50px;
  --background: #333333;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.64px;
  max-width: 238px;
  margin: 64px auto 0 auto;
}

.lottie-animation {
  max-width: 201px;
  margin: 0 auto;
}

.form {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
</style>
