<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <div class="top-content" ref="messagesContainer">
            <div class="avatars" v-if="otherPet && ownPet">
              <div class="box">
                <ion-img :src="ownPet.avatar" class="avatar" @click="goToPet(ownPet.id)" />
                <ion-img :src="otherPet.avatar" class="avatar" @click="goToPet(otherPet.id)" />
              </div>
              <p v-if="followingEachOther">Követitek egymást.</p>
              <p v-else-if="!followingOwn && followingOther">Követed őt.</p>
              <p v-else-if="!followingOther && followingOwn">Nem követed őt.</p>
              <p v-else>Nem követitek egymást.</p>
            </div>
            <div class="messages">
              <div
                class="row"
                v-for="message in messages"
                :key="message.id"
                :class="{
                  self: message.isFromMyself,
                  'no-bg': message.message === ':paw:',
                }"
              >
                <div class="profile">
                  <ion-img
                    v-if="showMessageAvatar(message)"
                    :src="message.isFromMyself ? ownPet.avatar : otherPet.avatar"
                    class="avatar"
                    @click="goToPet(message.isFromMyself ? ownPet.id : otherPet.id)"
                  />
                </div>
                <div class="message" v-if="message.message !== ':paw:'">{{ message.message }}</div>
                <div class="message" v-else>
                  <lottie-animation
                    ref="anim"
                    :autoPlay="false"
                    :playInterval="0"
                    :playOnClick="true"
                    :animationData="require('@/assets/images/lottie/petmenu-paw.json')"
                    class="lottie-anim"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="toolbar" :class="{ ios: isIos }">
            <input
              type="text"
              id="message"
              v-model="message"
              name="message"
              class="input"
              autocapitalize="sentences"
              @keyup.enter="sendMessage"
            />

            <ion-img
              :src="require('@/assets/images/icons/walk/paw_grey.svg')"
              alt="Integetés"
              class="icon"
              :style="'filter: brightness(0%);'"
              v-if="!message"
              @click="sendMessage(':paw:')"
            />
            <ion-img
              :src="require('@/assets/images/icons/chat/send.svg')"
              alt="Üzenet küldése"
              class="icon"
              @click="sendMessage"
              v-else
            />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { IonPage, IonContent, IonImg, IonInput, isPlatform } from '@ionic/vue'

import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'
import { useRoute, useRouter } from 'vue-router'

import { PET_ROUTE, PAGE_MESSAGES_ROUTE } from '@/constants/routes'

export default defineComponent({
  name: 'Chat',
  components: {
    IonPage,
    IonContent,
    IonImg,
    IonInput,
  },
  setup() {
    const route = useRoute()
    const { replace } = useNavigation()
    const { getMessagesByPet, sendMessageToPet } = usePets()

    const messages = ref([])
    const otherPet = ref(null)
    const ownPet = ref(null)
    const message = ref('')
    const followingEachOther = ref(null)
    const followingOther = ref(null)
    const followingOwn = ref(null)
    const targetPetId = computed(() => route.params.pet)
    const myPetId = computed(() => route.params.mypet)
    const messagesContainer = ref(null)

    const router = useRouter()

    const isIos = isPlatform('ios')

    onMounted(async () => {
      try {
        const response = await getMessagesByPet(targetPetId.value, myPetId.value)

        messages.value = response.messages
        otherPet.value = response.otherPet
        ownPet.value = response.ownPet
        followingEachOther.value = response.followingEachOther
        followingOther.value = response.followingOther
        followingOwn.value = response.followingOwn
      } catch (error) {
        console.error(error)
        replace({ name: PAGE_MESSAGES_ROUTE })
      } finally {
        setTimeout(() => {
          messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
        }, 0)
      }
    })

    const showMessageAvatar = message => {
      const index = messages.value.indexOf(message)

      const previousMessage = index > 0 && messages.value[index - 1]
      const nextMessage = index < messages.value.length - 1 && messages.value[index + 1]

      const differentPetPrev = previousMessage?.sender?.id !== message.sender?.id
      const differentPetNext = nextMessage?.sender?.id !== message.sender?.id

      return (
        (!differentPetPrev && !nextMessage) ||
        (!previousMessage && nextMessage && differentPetNext) ||
        (!differentPetPrev && differentPetNext) ||
        (!nextMessage && differentPetPrev) ||
        (differentPetPrev && differentPetNext)
      )
    }

    const sendMessage = async () => {
      let msg = message.value?.trim()

      if (!msg) {
        msg = ':paw:'
      }

      messages.value.push({
        id: msg + Math.random(),
        isFromMyself: true,
        message: msg,
        read_at: new Date(),
        receiver: otherPet.value,
        sender: ownPet.value,
      })

      message.value = ''
      setTimeout(() => {
        messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight
      }, 0)

      try {
        await sendMessageToPet(targetPetId.value, myPetId.value, msg)
      } catch (error) {
        console.error(error)
      }
    }

    const goToPet = async petId => {
      await router.push({ name: PET_ROUTE, params: { id: petId } })
    }

    return {
      message,
      messages,
      isIos,
      ownPet,
      otherPet,
      followingEachOther,
      followingOther,
      followingOwn,
      showMessageAvatar,
      messagesContainer,
      sendMessage,
      goToPet,
    }
  },
})
</script>

<style lang="scss" scoped>
.container,
.content {
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;
  position: relative;
  padding-bottom: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.top-content {
  flex-grow: 1;
  padding-bottom: 135px;

  overflow-y: auto;

  .avatars {
    margin-bottom: 50px;

    .box {
      display: flex;
      position: relative;
      justify-content: center;
      margin-top: 140px;
      margin-bottom: 20px;

      .avatar {
        width: 55px;
        height: 55px;
        margin-right: 12.5px;

        &:first-of-type {
          z-index: 2;
          margin-right: 0;
        }

        &::part(image) {
          border-radius: 9999px;
          border: 5px solid #fff;
          box-shadow: 3px 1px 5px rgba(0, 0, 0, 0.2);
        }
      }
    }

    p {
      text-align: center;
      font-size: 12px;
    }
  }

  .messages {
    .row {
      display: flex;
      margin: 20px 0;

      .profile {
        min-width: 40px;
        max-width: 40px;
        margin-right: 10px;
        align-self: flex-end;

        .avatar::part(image) {
          border-radius: 9999px;
        }
      }

      .message {
        background: #fff;
        box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.11);
        border-radius: 27px 27px 27px 2px;
        padding: 11px 15px;
        margin-left: 0;
        margin-right: 24px;
      }

      &.self {
        flex-direction: row-reverse;

        .profile {
          min-width: 20px;
          max-width: 20px;
          margin-right: 0;
          margin-left: 4px;
        }

        .message {
          background: linear-gradient(90deg, #f1f1f1 0%, #d1deea 100%);
          border-radius: 27px 27px 2px 27px;
          margin-left: 50px;
          margin-right: 0;
          text-align: right;
        }
      }

      &.no-bg .message {
        position: relative;
        background: none;
        box-shadow: none;
        padding: 0;
      }
    }
  }
}

.toolbar {
  position: fixed;
  left: 20px;
  right: 85px;
  bottom: calc(75px + env(safe-area-inset-bottom));

  &.ios {
    bottom: calc(60px + env(safe-area-inset-bottom));
  }

  .input {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 25px;
    padding: 9px 48px 9px 11px;
    width: 100%;
    font-size: 15px;
    min-height: 50px;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 15px;
    z-index: 2;
    transform: translateY(-50%);

    &::part(image) {
      min-height: 24px;
      max-height: 24px;
    }
  }
}

.lottie-anim {
  height: 71px;
  width: 71px;
}
</style>
