<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <template v-if="messages.length > 0">
            <div
              class="message-box"
              :class="{
                unread: !message.read_at,
                hasPaw: message.message === ':paw:',
              }"
              @click="goToChat(message.from, message.to)"
              v-for="(message, index) in messages"
              :key="'message-' + index"
            >
              <div class="from-avatar-box">
                <ion-img :src="message.from.avatar" class="from-avatar" />

                <ion-img :src="message.to.avatar" class="to-avatar" />
              </div>
              <p v-if="message.message !== ':paw:'">
                {{ message.message }}
              </p>
              <lottie-animation
                v-else
                ref="anim"
                :autoPlay="false"
                :playInterval="0"
                :playOnClick="true"
                :animationData="require('@/assets/images/lottie/petmenu-paw.json')"
                class="lottie-anim"
              />
            </div>
          </template>
          <div class="centered" v-else>
            <div class="pet-container" @click="goCreatePost">
              <ion-img :src="require(`@/assets/images/animated/dog-head.gif`)" alt="pet" class="pet-icon" />
              <ion-img
                :src="require('@/assets/images/icons/circle-plus-black.svg')"
                alt="Hozzáadás"
                class="pet-plus-icon"
              />
            </div>
            <h1>Még nem érkezett üzeneted.</h1>
            <p>
              Tölts föl egy képet, kezdj el követni másokat,<br />
              beszélgessetek, használd a séta funkciónkat<br />
              és induljatok közös kalandokra!
            </p>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { IonPage, IonContent, IonImg } from '@ionic/vue'

import { POST_CREATE_ROUTE, PAGE_CHAT_ROUTE } from '@/constants/routes'

import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'

export default defineComponent({
  name: 'Messages',
  components: {
    IonPage,
    IonContent,
    IonImg,
  },
  setup() {
    const messages = ref([])

    const { navigateTo } = useNavigation()
    const { getMessages } = usePets()

    const goCreatePost = async () => {
      await navigateTo({ name: POST_CREATE_ROUTE })
    }

    const goToChat = async (from, to) => {
      await navigateTo({ name: PAGE_CHAT_ROUTE, params: { pet: from.id, mypet: to.id } })
    }

    onMounted(async () => {
      try {
        messages.value = await getMessages()
      } catch (error) {
        console.error(error)
      }
    })

    return {
      messages,
      goCreatePost,
      goToChat,
    }
  },
})
</script>

<style lang="scss" scoped>
.ios .message-box:first-of-type {
  margin-top: 160px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}

.pet-container {
  position: relative;
  cursor: pointer;

  .pet-icon {
    max-width: 93px;
    margin: 0 auto;
  }

  .pet-plus-icon {
    position: absolute;
    max-width: 32px;
    min-width: 32px;
    border: 4px #fff solid;
    right: 50%;
    bottom: -5px;
    transform: translateX(45px);
    border-radius: 50%;
  }
}

.content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.message-box {
  background: #fff;
  box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.11);
  border-radius: 0px 27px 27px 0px;
  padding: 16px 36px 16px 50px;
  margin: 32px 0px 32px 32px;
  position: relative;
  height: 50px;

  &.hasPaw {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lottie-animation {
    width: 40px;
    height: 40px;
    position: relative;
    top: 5px;
    left: -10px;
  }

  &:first-of-type {
    margin-top: 120px;
  }

  &.unread {
    animation: unread 5s infinite;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .from-avatar-box {
    width: 55px;
    height: 55px;
    position: absolute;
    left: -32px;
    top: 0;
    transform: translateY(-15%);

    .from-avatar::part(image) {
      border-radius: 9999px;
      border: 5px solid #ffffff;
      box-shadow: 3px 1px 5px rgba(0, 0, 0, 0.2);
    }

    .to-avatar {
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: -15px;
      right: -15px;

      &::part(image) {
        border-radius: 9999px;
      }
    }
  }
}

@keyframes unread {
  25% {
    background: #c2d6fe;
  }
  50% {
    background: #fff;
  }
}
</style>
