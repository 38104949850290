<template>
  <ion-page>
    <ion-content fullscreen>
      <div class="container">
        <div class="content">
          <PetSelector disabled newDesign :pets="pets" />

          <h1 class="top" v-if="isPasswordReset">Családtag bejelentkezése</h1>
          <h1 class="top" v-else>Családhoz csatlakozás</h1>

          <p v-if="isPasswordReset">
            <b>{{ invitedBy }}</b> fiókjának családtagként való bejelentkezéséhez add meg a jelszavad. A későbbiekben
            ezzel, és az email címeddel fogsz tudni belépni:
          </p>

          <p v-else>
            <b>{{ invitedBy }}</b> meghívott, hogy együtt kezeljétek
            <b>{{ pets.map(p => p.name).join(', ') }}</b> kedvence profilját. Ha szeretnél csatlakozni, kattints az
            Elfogadás gombra.
          </p>

          <div v-if="userDetails && isPasswordReset">
            <ion-img class="user-placeholder" :src="require('@/assets/images/placeholder.jpg')" />

            <h2>{{ userDetails.name }}</h2>
            <h3>{{ userDetails.email }}</h3>
          </div>

          <form @submit.prevent="submit" novalidate class="form" v-if="isPasswordReset">
            <div class="form-group">
              <ion-label for="password" class="control-label">Jelszó:</ion-label>
              <ion-input
                type="password"
                id="password"
                v-model="validations.password.$model"
                name="password"
                required
                :class="{ 'is-invalid': validations.password.$error || hasServerValidationError('password') }"
                class="form-control"
              >
              </ion-input>
              <ErrorMessage :field="validations.password" />
              <ServerValidation field="password" :server-validation="serverValidation" />
            </div>
            <div class="form-group">
              <ion-label for="password-confirm" class="control-label">Jelszó ismét:</ion-label>
              <ion-input
                type="password"
                id="password-confirm"
                v-model="validations.passwordConfirm.$model"
                name="password-confirm"
                required
                :class="{
                  'is-invalid': validations.passwordConfirm.$error || hasServerValidationError('password_confirmation'),
                }"
                class="form-control"
              >
              </ion-input>
              <ErrorMessage :field="validations.passwordConfirm" />
              <ServerValidation field="password_confirmation" :server-validation="serverValidation" />
            </div>
            <ion-button
              type="submit"
              id="submit"
              expand="full"
              shape="round"
              :disabled="isLoading"
              strong="true"
              class="btn"
            >
              Belépés
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </form>

          <template v-if="!isPasswordReset">
            <ion-button
              @click.prevent="accept"
              type="submit"
              id="submit"
              expand="full"
              shape="round"
              strong="true"
              class="btn"
            >
              Elfogadás
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>

            <p class="subtext">
              A csatlakozást követően lehetséges, hogy érdemes kijelentkezned a fiókodból a beállítások menüben, majd
              jelentkezz be újra.
            </p>
          </template>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import * as sha512 from 'js-sha512'
import { computed, defineComponent, toRefs, onMounted, toRef, reactive, ref } from 'vue'
import { IonPage, IonContent, IonLabel, IonInput, IonButton, IonRippleEffect, IonImg } from '@ionic/vue'
import { useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import useToast from '@/composables/toast'
import useNavigation from '@/composables/navigation'
import useAuth from '@/composables/auth'
import usePosts from '@/composables/posts'
import useFormControl from '@/composables/formControl'

import PetSelector from '@/components/Pet/PetSelector'

import { LOGIN_ROUTE } from '@/constants/routes'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'InviteAccept',
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonInput,
    IonButton,
    IonRippleEffect,
    IonImg,
    ErrorMessage,
    ServerValidation,
    PetSelector,
  },
  setup() {
    const route = useRoute()
    const { goToFeed } = usePosts()
    const { openToast } = useToast()
    const { navigateTo } = useNavigation()
    const { login, inviteDetails } = useAuth()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const pets = ref([])
    const invitedBy = ref('Valaki')
    const userDetails = ref(null)
    const isPasswordReset = ref(false)

    const state = reactive({
      password: '',
      passwordConfirm: '',
      isLoading: false,
    })

    const rules = {
      password: { required },
      passwordConfirm: {
        required,
        password_mismatch: val => val === state.password,
      },
    }

    const validations = useVuelidate(rules, {
      password: toRef(state, 'password'),
      passwordConfirm: toRef(state, 'passwordConfirm'),
    })

    const token = computed(() => route.params.token)

    function resetForm() {
      state.password = ''
      state.passwordConfirm = ''
    }

    async function submit() {
      validations.value.$touch()

      if (validations.value.$invalid || state.isLoading) return

      try {
        serverValidation.value = {}
        state.isLoading = true

        await httpClient.save(`users/invite/accept`, {
          email: userDetails.value.email,
          token: token.value,
          password: sha512(state.password),
          password_confirmation: sha512(state.passwordConfirm),
        })

        openToast({ message: 'A jelszavadat sikeresen beállítottuk' })
        await login({ email: userDetails.value.email, password: state.password })

        resetForm()

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        state.isLoading = false
      }
    }

    const accept = async () => {
      try {
        await httpClient.save(`users/invite/accept`, {
          email: userDetails.value.email,
          token: token.value,
          password: 'pw',
          password_confirmation: 'pw',
        })

        await goToFeed()
      } catch {
        console.warn('Nem sikerült elfogadni a meghívást.')
      }
    }

    onMounted(async () => {
      try {
        const data = await inviteDetails(token.value)

        pets.value = data.pets
        invitedBy.value = data.invitedBy
        userDetails.value = data.user
        isPasswordReset.value = data.passwordReset === 1
      } catch {
        await navigateTo({ name: LOGIN_ROUTE })
      }
    })

    return {
      submit,
      accept,
      ...toRefs(state),
      pets,
      invitedBy,
      userDetails,
      isPasswordReset,
      validations,
      serverValidation,
      hasServerValidationError,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&family=Open+Sans:wght@400;800&display=swap');

.pet-select {
  height: unset;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 16px !important;
  text-align: center;
  letter-spacing: -0.3px;
  line-height: 19.5px;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  text-align: center;
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
}

.user-placeholder {
  margin: 0 auto;
  max-width: 45px;
  min-width: 45px;
  margin-bottom: 10px;

  &::part(image) {
    border-radius: 9999px;
  }
}

p {
  margin: 0 30px;
  text-align: center;
  color: #222222;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 30px;
}

ion-input {
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 31px;
  border: none;
  min-height: 50px;
  --padding-start: 22px;
  --padding-end: 22px;
}

ion-label {
  padding-left: 22px;
}

ion-button {
  height: 50px;
  --background: #333333;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.64px;
  max-width: 238px;
  margin: 64px auto 0 auto;
}

.lottie-animation {
  max-width: 201px;
  margin: 0 auto;
}

.form {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

p.subtext {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12.5px;
  color: #222222;
  margin-top: 22px;
}
</style>
